import React from "react";
import { Carousel } from "react-responsive-carousel";
import Banne1 from "../../assets/banner/f_00006_proc.jpg";
import Banne2 from "../../assets/banner/Comp1_00000_proc.jpg";
import Banne3 from "../../assets/banner/a_00000_proc.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import { useNavigate } from "react-router-dom";

function BannerCarousel() {
  const navigate = useNavigate();

  return (
    <div>
      <Carousel autoPlay infiniteLoop showThumbs={false} className="carousel">
        <div onClick={() => navigate("/product/category/Computadores")}>
          <img className="banner" src={Banne1} alt="Imagen 1" />
        </div>
        <div onClick={() => navigate("/product/category/Impresoras")}>
          <img className="banner" src={Banne2} alt="Imagen 2" />
        </div>
        <div onClick={() => navigate("/product/category/Energia")}>
          <img className="banner" src={Banne3} alt="Imagen 3" />
        </div>
      </Carousel>
    </div>
    
  );
}

export default BannerCarousel;
